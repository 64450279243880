/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica-Light.woff2') format('woff2'),
        url('./assets/fonts/Helvetica-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica.woff2') format('woff2'),
        url('./assets/fonts/Helvetica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica-Bold.woff2') format('woff2'),
        url('./assets/fonts/Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
	font-display: swap;
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

:root{
    --naranja: #FC4F1E;
    --fuente: #353535;
    --azul: #0A51A3;
    --azul-border: #0A51A3;
    --gris: #ACA9A9;
    --blanco: #fff;
    --footer: #6D6D6D;
    --rojo: #FC5757;
}

.ng-invalid:not(form), :required {
    transition: all 0.15s ease-out;
	border-color: #cb8482 !important;
}

::-webkit-scrollbar {
	width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--blanco);
    transition: all 0.15s ease-out;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: all 0.15s ease-out;
	background: #434343;
	border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    transition: all 0.15s ease-out;
	background: var(--azul);
}

html, body { height: 100%; }
body { margin: 0; height: auto; font-family: "Helvetica", "Helvetica Neue", sans-serif; }

a{
    outline: none;
    text-decoration: none;
	cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
}

.button{
    background-color: var(--azul);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.35rem;
    padding: 0.8rem 1.75rem;
    border-radius: 12px;
    line-height: 1;
    font-weight: bold;
    outline: none;
    border-color: transparent;
    width: max-content;
    cursor: pointer;
    transition-delay: 0.2s;
    transition: all 0.2s ease-out;
    letter-spacing: 0.05rem;
    font-family: "Helvetica" !important;
	&.button[disabled] {
		background-color: #a9a9a9;
		transition-delay: 0.2s;
        transition: all 0.2s ease-out;
	}
	&.full{
		width: auto;
	}
	&:hover{
		transition-delay: 0.2s;
        transition: all 0.2s ease-out;
	}
    &.clear{
        background-color: transparent;
        border: 1px solid var(--fuente);
        color: var(--fuente);
		&:hover{
			background-color: var(--fuente);
			color: var(--blanco);
		}
    }
	&.line{
		background: transparent;
		color: var(--fuente);
		border-bottom: 1px solid var(--fuente);
		border-radius: 0px;
		padding: 0rem !important;
	}
	&.alert{
		background-color: #cd0707 !important;
		&:hover{
			background-color: #e32929 !important;
		}
	}
	&.normal{
		background-color: #396599;
	}
	&.circle{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		padding: 0px !important;
		background-color: #325783;
		mat-icon{
			font-size: 1.1rem;
			width: auto;
			height: auto;
		}
	}
}

.loading{
	width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    background-color: #0000006b;
    z-index: 1000;
	.mat-mdc-progress-spinner {
		--mdc-circular-progress-active-indicator-color: #fdfbfb !important;
	}
}



.success-snack-bar {
    background-color: #397d1f;
    color: #fff;
}

.alert-snack-bar {
    background-color: #d13520;
    color: #fff;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface{
	background-color: transparent !important;
}

input:disabled, input[readonly], textarea:disabled, textarea[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

input, select, textarea{
    border: 2px solid var(--fuente);
    color: var(--fuente);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 12px;
    outline: none;
}

img {
	aspect-ratio: attr(width) / attr(height);
}

select {
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28138, 138, 138%29"></polygon></svg>');
    background-origin: content-box;
    background-position: right -0.4rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
	-webkit-appearance: none;
}

label{
    display: flex;
    gap: 1rem;
    align-items: center;
}

$background-color_1: #fff;
$background-color_2: #fff;
$background-color_3: var(--azul);

/* Hide the browser's default radio button */
/* Create a custom radio button */
/* On mouse-over, add a grey background color */
/* When the radio button is checked, add a blue background */
/* Create the indicator (the dot/circle - hidden when not checked) */
/* Show the indicator (dot/circle) when checked */
/* Style the indicator (dot/circle) */
.radiobutton {
	display: block;
	position: relative;
	cursor: pointer;
    width: 25px;
    height: 26px;
    flex: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ {
				.checkmark {
					background-color: $background-color_3;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark {
					background-color: $background-color_2;
				}
			}
		}
	}
	.checkmark {
		&:after {
			top: 9px;
			left: 9px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: white;
		}
	}
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: $background-color_1;
	border-radius: 50%;
    border: 1px solid #353535;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}

$background-color_1: #fff;
$background-color_2: #ccc;
$background-color_3: var(--azul);

.checkbox {
	display: block;
	position: relative;
	cursor: pointer;
    width: 25px;
    height: 26px;
    flex: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark-box {
					background-color: $background-color_3;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark-box {
					background-color: $background-color_2;
				}
			}
		}
	}
	.checkmark-box {
		&:after {
			left: 9px;
			top: 5px;
			width: 5px;
			height: 10px;
			border: solid rgb(255, 255, 255);
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.checkmark-box {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
    border: 1px solid #353535;
    border-radius: 5px;
	background-color: $background-color_1;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}